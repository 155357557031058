@tailwind base;
@tailwind components;
@tailwind utilities;



*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* overflow-x: hidden; */
  font-family: 'Plus Jakarta Sans';
}

/* Custom scrollbar hide class */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}
.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.spinner {
   width: 56px;
   height: 56px;
   border-radius: 50%;
   background: conic-gradient(#0000 10%,#ff9c47);
   -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 9px),#000 0);
   animation: spinner-zp9dbg 1s infinite linear;
}

@keyframes spinner-zp9dbg {
   to {
      transform: rotate(1turn);
   }
}



